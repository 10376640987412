@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

// Fonts
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

hr {
    margin: 2em 0;
}

h1 {
    font-size: 2rem;
    font-family: $font;
    margin: 0.5em 0;

    @include xs {
        font-size: 1.75rem;
    }
}

h2 {
    font-size: 1.75rem;
    font-family: $font;
    margin: 0.5em 0;
}

h3 {
    font-size: 1.5rem;
    font-family: $font;
    margin: 0.5em 0;
}

h4 {
    font-size: 1.25rem;
    font-family: $font;
    margin: 0.5em 0;
}

h5 {
    font-size: 1.125rem;
    font-family: $font;
    margin: 0.5em 0;
}

h6 {
    font-size: 1rem;
    font-family: $font;
    margin: 0.5em 0;
}

h1, h2, h3, h4, h5, h6 {
    &.g-section-prompt {
        display: block;
        margin-top: 0;
        margin-bottom: 0.75em;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1rem;
        color: $primary;
    }
}

a {
    color: $primary;
    font-weight: 600;
}

p {
    font-size: 1.125rem;
    line-height: 24px;
    letter-spacing: 2%;
    margin-bottom: 1.125rem;

    @include md {
        font-size: 1rem;
        line-height: 20px;
    }
}

ul {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 0.25rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

ol {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 0.25rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

blockquote {
    background: rgba(0, 0, 0, 0.03);
    margin: 0 0 1rem;
    padding: 1.25rem;
    border-left: 0.75rem solid $primary;
}

img.fr-bordered {
    border: 5px solid $primary;
}

table {
    &.fr-alternate-rows {
        tr {
            &:nth-child(odd) {
                background: $light-grey;
            }
        }
    }

    tr {
        th {
            background: $primary;
            padding: 0.5rem 1rem;
            text-align: left;
            font-weight: 300;
            font-size: 112.5%;
            color: $white;
        }

        td {
            padding: 0.5rem 1rem;
            font-weight: 300;
            font-size: 112.5%;

            &.fr-highlighted {
                background: $orange;
                color: $white;
            }

            &.amount {
                position: relative;
                padding-left: 2rem;
                text-align: right;

                &::before {
                    content: "$";
                    position: absolute;
                    left: 1rem;
                }
            }

            .fr-file {
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                border-radius: 3px;
                border: 0 none;
                text-indent: -999rem;

                &::before {
                    left: 50%;
                    text-indent: initial;
                    transform: translate(-50%, -50%);
                }

                &::after {
                    content: none;
                }

                &:hover {
                    background: $primary;
                }
            }
        }
    }
}

.container {
    padding: 0 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;

    &.reverse {
        @include xs {
            flex-direction: column-reverse;
        }

        .column {
            &:first-child {
                margin-top: 1em;
            }
        }
    }

    @include md {
        flex-direction: column;
    }
}

#home main .row {
    @include lg {
        flex-direction: column;
    }

    &.reverse {
        @include lg {
            flex-direction: column-reverse;
        }
    }
}

.tilegroup {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    margin-left: 0;

    @include md {
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }

    .tilegroup-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: $secondary;
        padding: 1.5em;
        min-height: 120px;
        box-shadow: 3px 3px 3px rgba(39, 113, 54, 0.35);
        clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

        @include md {
            padding: 1em;
            min-height: auto;
        }

        .text {
            width: 75%;
            margin-top: 0;
            padding: 1em;
            border-left: 2px solid $primary;
            color: $white;

            @include xs {
                padding: 0.5em;
            }

            h3 {
                font-size: 1.125rem;
                margin: 0;

                @include xs {
                    font-size: 1rem;
                }
            }

            p {
                margin-top: 0.5em;
                color: $white;
            }
        }
    }
}

.icon-two-col-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;

    @include md {
        gap: 1em 2em;
    }

    @include xs {
        gap: 1em;
    }

    .icon-item-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 180px;
        border: 1px solid $primary;
        padding: 1.5em;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgba(39, 113, 54, 0.35);

        .icon-img {
            width: 2.75em;

            @include xs {
                width: 2.5em;
            }
        }

        .icon-item-title {
            font-size: 1.125rem;
            font-weight: 600;
            color: $primary;
            font-family: $font;
            margin: 0.5em 0;

            @include xs {
                font-size: 1rem;
            }
        }
    }
}

.icon-three-col-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;

    @include md {
        gap: 1em 2em;
    }

    @include xs {
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }

    .icon-item-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $primary;
        padding: 1.5em;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgba(39, 113, 54, 0.35);

        .icon-img {
            width: 2em;
            margin-bottom: 0.25em;

            @include xs {
                width: 1.5em;
            }
        }

        .icon-item-title {
            font-size: 1.125rem;
            font-weight: 600;
            color: $primary;
            font-family: $font;
            margin: 0.5em 0;

            @include xs {
                font-size: 1rem;
            }
        }
    }
}

.g-section-prompt {
    display: block;
    color: $primary;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 2%;
    margin-bottom: 0.75em;
}

.g-section-title {
    display: block;
    font-size: 2rem;
    font-family: $font;
    line-height: 40px;
    letter-spacing: 2%;
    margin-bottom: 0.5em;

    @include md {
        font-size: 1.75rem;
        line-height: 36px;
    }

    @include xs {
        font-size: 1.5rem;
        line-height: 32px;
    }
}

.g-link-arrow {
    display: block;
    width: fit-content;
    text-decoration: none;
    line-height: 1.2;
    font-weight: 600;
    font-size: 1rem;
    color: $white;

    &:after {
        content: '';
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/img/arrow-right.svg');
        background: url('/img/arrow-right.svg') no-repeat / contain;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.375rem;
        transition: 0.1s ease-in;
        vertical-align: middle;
    }

    &:hover {
        &:after {
            transform: rotate(39deg);
            transition: 0.1s ease-in;
        }
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- McNamara

// -- Header
header {
    position: relative;
    z-index: 3;
    background: $primary;

    .desktop-menu {
        width: 100%;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1em 40px;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1rem 20px;
            }
        }

        .column.left {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            nav {
                display: flex;
                align-items: center;
                margin-left: 4em;

                ul {
                    li {
                        margin: 0 1em;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:hover {
                            ul {
                                display: block;
                                opacity: 1;
                            }
                        }

                        a {
                            color: $white;
                            font-size: 1rem;
                            text-decoration: none;

                            &:hover {
                                color: $orange;
                            }

                            &:not(:only-child):after {
                                content: " \25BE";
                                color: $white;
                                display: inline-block;
                                position: initial;
                                margin-left: 8px;
                                background: none;
                                width: fit-content;
                            }
                        }

                        ul {
                            display: none;
                            opacity: 0;
                            margin: 0;
                            padding-left: 0;
                            width: fit-content;
                            position: absolute;
                            top: 65%;
                            flex-direction: column;
                            border-top: 3px solid $orange;
                            background-color: white;
                            z-index: 40;

                            overflow: hidden;
                            box-shadow: 5px 5px 13px 0px rgba(0, 0, 0, 0.15);

                            li {
                                margin: 0;
                                padding: 0.25em 2em 0.25em 1.5em;
                                display: block;
                                list-style: none;
                                transition: all 100ms ease;

                                &:first-child {
                                    padding-top: 1em;
                                }

                                &:last-child {
                                    padding-bottom: 1em;
                                }

                                &:hover {
                                    transition: all 100ms ease;
                                    a {
                                        color: $orange;
                                    }
                                }

                                a {
                                    line-height: normal;
                                    transition: all 100ms ease;
                                    font-size: 1rem;
                                    width: 100%;
                                    color: $primary;
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }

        .column.right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        .logo {
            display: block;
            width: auto;
            max-width: 275px;
            height: 50px;
            object-position: center;
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include lg {
        display: none;
    }
}

.mobile-menu {
    display: none;
    @extend .container;

    @include lg {
        position: relative;
        display: block;
        z-index: 101;
        padding: 1.5em 40px;
    }

    @include md {
        padding: 1.125em 20px;
    }

    .row {
        @include md {
            flex-direction: row;
        }
    }

    .logo-link {
        z-index: 1000;
    }

    .logo {
        width: 6em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 1.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                background: $white;
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background: $white;
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $white;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $secondary;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    z-index: 100;

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: none !important;

    @include md {
        display: block !important;
    }

    nav {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-left: 0;

            li {
                text-align: left;
                padding: 0.5em 2em;

                a {
                    color: $white;
                    font-family: $font;
                    font-size: 1.125rem;
                    text-decoration: none;

                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }
}

// -- Footer
footer {
    border-top: 1px solid $white;

    .footer-top {
        background: $secondary;

        @include md {
            padding: 1.25em 0;
        }

        .container {
            padding-top: 1.5em;
            padding-bottom: 1.5em;

            @include md {
                padding-top: 1em;
                padding-bottom: 0;
            }
        }

        .row {
            @include md {
                display: flex;
                flex-direction: column;
            }
        }

        .footer-col-left {
            max-width: 500px;

            @include md {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .logo {
                height: 3.5em;

                @include md {
                    width: 130px;
                }
            }

            p {
                color: $white;
                font-size: 0.85rem;
                margin-bottom: 0;
            }

            .subscribe-form-container {
                margin: 2em 0;

                @include md {
                    width: 100%;
                    margin: 1em 0;
                }

                span {
                    display: block;
                    font-size: 1rem;
                    letter-spacing: 1.2px;
                    color: $white;
                    text-transform: uppercase;
                    margin-bottom: 0.5em;

                    @include md {
                        letter-spacing: normal;
                    }
                }

                #subscribe-form {
                    display: flex;
                    align-items: flex-start;

                    fieldset {
                        max-width: 250px;

                        input {
                            margin-bottom: 0;
                        }
                    }

                    .button.subscribe {
                        width: 39.8px;
                        height: 39.8px;
                        margin: 0;
                        padding: 16px;
                        background: $primary;

                        &:after {
                            width: 1.35em;
                            height: 1.35em;
                            position: absolute;
                            top: 9px;
                            left: 9px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .footer-col-right {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            @include lg {
                align-items: flex-start;
            }

            @include md {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 0;
            }

            .footer-menu {
                @include md {
                    text-align: left;
                    padding-left: 0;
                    margin: 0;
                }

                &:nth-child(2) {
                    margin: 0 1em;

                    @include xs {
                        margin: 0;
                    }
                }

                nav ul {
                    text-align: right;

                    @include md {
                        text-align: left;
                        padding-left: 0;
                    }

                    li {
                        list-style-type: none;
                        margin-bottom: 0.5em;

                        a {
                            color: $white;
                            text-decoration: none;

                            &:hover {
                                color: $orange;
                            }
                        }

                        ul {
                            display: none;
                        }
                    }
                }
            }

            .social-media {
                display: flex;
                justify-content: flex-end;
                margin: 1em 0 0;

                @include md {
                    justify-content: flex-start;
                    padding-left: 0;
                }

                li {
                    list-style-type: none;
                    padding: 0.5em;

                    @include md {
                        margin: 0 0.5em 0 0;
                        padding: 0;
                    }

                    &:hover {
                        background-color: $orange;
                    }

                    img {
                        margin: 0;
                        width: 100%;
                        height: 100%;
                    }

                    @include xs {
                        margin: 0 1em 0 0;
                    }
                }
            }
        }
    }

    .footer-bottom {
        background-color: $dark-grey;

        .row {
            align-items: flex-end;

            @include md {
                align-items: flex-start;
            }
        }

        .container {
            padding-top: 1em;
            padding-bottom: 1em;

            @include xs {
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }
        }

        span {
            color: $black;
            font-size: 0.75rem;

            a {
                color: $black;
                font-weight: 400;
            }

            &.disclaimer {
                text-align: left;
                max-width: 800px;
                padding-top: 0;
            }

            @include md {
                padding-top: 1em;
            }
        }
    }
}

// CTA

#cta {
    width: 100%;
    height: 33.45em;
    min-height: 535px;
    background-color: $secondary;
    overflow: hidden;
    margin-top: 3em;
    display: block;

    @include lg {
        height: 100%;
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;

        @include lg {
            padding: 0;
        }
    }

    .cta-bg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-image: url("/img/cta-bg-2.png");
        background-size: cover;

        &.desktop {
            display: block;
            background-image: url("/img/cta-bg-2.png");
            background-size: cover;
            background-position: right;

            @include lg {
                display: none;
            }

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 12em;
                height: 100%;
                background: linear-gradient(90deg, #303139 0em, transparent);
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: $secondary;
                -webkit-clip-path: polygon(
                    61% 55%,
                    67% 0%,
                    43% 100%,
                    100% 100%,
                    100% 0%,
                    78% 0%,
                    71% 0%,
                    67% 0%,
                    67% 0%,
                    68% 0%,
                    67% 0%,
                    68% 0%,
                    57% 73%,
                    69% 0%,
                    67% 0%
                );
                clip-path: polygon(
                    61% 55%,
                    67% 0%,
                    43% 100%,
                    100% 100%,
                    100% 0%,
                    78% 0%,
                    71% 0%,
                    67% 0%,
                    67% 0%,
                    68% 0%,
                    67% 0%,
                    68% 0%,
                    57% 73%,
                    69% 0%,
                    67% 0%
                );
            }
        }

        &.mobile {
            display: none;
            background-size: cover;
            background-position: center;

            @include lg {
                display: block;
                height: 400px;
            }

            @include xs {
                height: 300px;
            }
        }
    }

    .cta-form-container {
        position: absolute;
        top: 50%;
        right: 0;
        width: 514px;
        padding: 2em;
        transform: translate(0, -50%);
        z-index: 2;

        @include lg {
            position: relative;
            top: unset;
            left: unset;
            background: $secondary;
            width: 100%;
            transform: none;
        }

        h1 {
            margin: 0;
            color: $white;

            @include md {
                font-size: 1.75rem;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }

        p {
            margin: 0.5em 0;
            color: $white;
        }
    }

    #cta-form {
        margin: 1em 0 0;

        .button.primary {
            margin: 0.5em 0;
        }
    }

    .triangle-green {
        display: block;
        position: absolute;
        bottom: 0;
        left: 613px;
        z-index: 2;

        @include lg {
            top: 242px;
            bottom: unset;
            left: unset;
            right: -205px;
        }

        @include xs {
            top: 142px;
            bottom: unset;
            left: unset;
            right: -200px;
        }
    }
}

// -- Services page

#services-row {
    .service-item-row {
        position: relative;
        height: 100%;
        width: 100%;
        margin-bottom: 2em;
        background: #2f3139;
        border-radius: 20px 0 20px 0;
        display: block;
        background-position: right;
        background-repeat: no-repeat;
        background-size: 55%;

        @include lg {
            background-size: cover;
            background-position: center;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgb(47 49 57 / 80%);
            border-radius: 20px 0 20px 0;

            @include lg {
                background: rgb(47 49 57 / 90%);
            }
        }

        .service-item-contents {
            max-width: 650px;
            z-index: 2;
            padding: 2em 4em 2em 2em;
            display: block;
            position: relative;

            &.checklists {
                &:after {
                    right: -220px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: -200px;
                width: 15em;
                height: 100%;
                z-index: 3;
                background: linear-gradient(
                    270deg,
                    transparent,
                    #30313a calc(100% - 1rem)
                );

                @include lg {
                    display: none;
                }
            }

            .content-block {
                margin: 0;

                .text {
                    max-width: 100%;
                    margin-top: 0;
                    flex-basis: initial;

                    > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            h4.service-item-head {
                display: block;
                color: $white;
                font-size: 2rem;
                margin: 0.5em 0;

                @include xs {
                    font-size: 1.75rem;
                }
            }

            p {
                color: $white;
            }

            img {
                margin-top: 0;
            }

            .service-item-icon {
                height: 50px;
                object-fit: contain;

                @include xs {
                    height: 32px;
                }
            }
        }
    }
}

// -- Business Health Check page

#business-health-check {
    #cta {
        margin-top: 6em;
    }
}

// health-check-form
#health-check-form {
    margin-top: 40px;

    .green-form-section {
        background-color: $secondary;
        padding: 2em 2em 6em 2em;
        margin: 0 0 3em;

        @media (max-width: 768px) {
            padding: 2em 2em 5em;
        }

        label {
            display: block;
            padding-bottom: 0.5em;
            color: $white;
        }

        .button.primary {
            margin: 1em 0 0;
            width: fit-content;
        }
    }

    .row-input {
        width: 100%;
        display: grid;
        align-items: baseline;
        gap: 2em;
        grid-template-columns: repeat(2, 1fr);

        @media (max-width: 768px) {
            gap: 0;
            grid-template-columns: repeat(1, 1fr);
        }

        .input-questions {
            width: 100%;
        }
    }

    .question {
        margin: 1em 0;
        font-size: 1.2em;
    }

    .answer {
        display: flex;
        justify-content: center;

        span.answer_option {
            display: flex;
            margin: 0.4em 2em;

            * {
                cursor: pointer;
                padding: 1em 1em;
            }

            label {
                font-size: 1.2em;
                position: relative;
                top: -4px;
                padding: 0.5em 0.5em 1em 1em;
            }

            input {
                width: auto;
                margin-bottom: 0;
                height: 2.3em;
            }
        }
    }

    .small-prompt {
        font-size: 1.25rem;
        margin-left: 2em;
        vertical-align: bottom;

        @media (max-width: 768px) {
            margin: 0;
        }
    }

    .input-questions {
        margin-bottom: 25px;
    }

    input {
        width: calc(100% - 1em);
        margin-bottom: 0px !important;
    }

    textarea {
        // height: initial;
    }

    span.error {
        margin-bottom: 25px;
    }

    div > button.button {
        cursor: pointer;
        float: left;
    }

    .subscription {
        display: flex;
        align-items: center;

        #subscription_checkbox {
            width: auto !important;
            position: relative;
            top: -2px;
        }

        label {
            color: $black;
            font-size: 1em;
            font-weight: bold;
        }
    }

    // Questionnaire form container

    .explanation {
        padding: 1em 0;
        font-size: 1.25em;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
    }

    // slide

    .slick-slide::before {
        background: none;
        opacity: 0;
        display: none;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 1em;
    }

    .slick-prev,
    .slick-next {
        top: auto;
        bottom: -40px;
        left: auto;
        height: 50px;
        background: #ffffff;
        color: #000000;
        border: 2px solid black;
        font-size: 1rem;
        z-index: 9999;

        &::before {
            content: "";
            font-size: unset;
            line-height: normal;
        }
    }

    .slick-prev {
        right: 140px;

        @media (max-width: 768px) {
            right: 100px;
        }
    }

    .slick-next {
        right: 0;
    }

    .form-container {
        padding: 2em;
        background: $secondary;
        border-radius: 3px;
        margin-top: 2em;

        @media (max-width: 768px) {
            padding: 40px;
        }

        .paging-info {
            color: $white;
        }

        .buttons-container {
            margin-top: 3em;
        }
        button {
            -webkit-appearance: none;
            padding: 1em 2em;
            width: 140px;
            text-align: center;
            background: none;
            border: 1px solid $white;
            border-radius: 0;
            color: $white;

            &:after {
                display: none;
            }

            @media (max-width: 768px) {
                padding: 0.5em 1em;
                width: 100px;
                bottom: -55px;
            }

            &.btn-white {
                height: auto;
                background: white;
                color: black;
                border: 2px solid black;
            }
            &.btn-submit {
                background: black;
                color: white;
            }
        }

        .float-right {
            float: right;
        }

        .radio-container {
            display: inline-block;
            margin-top: 2em;
        }

        input[type="radio"] {
            opacity: 0;
            height: 0;
            width: 0;
        }

        input[type="radio"] ~ label {
            opacity: 1;
            border: 1px solid $white;
            padding: 0.75em 2.5em;
            margin-right: 1em;
            background: $secondary;
            color: $white;

            &:before {
                display: none !important;
            }

            &:after {
                display: none !important;
            }
        }

        input[type="radio"]:active + label {
            opacity: 1;
            border: 1px solid $white;
            background: $secondary;
            color: $white;
        }

        input[type="radio"]:checked + label {
            opacity: 1;
            border: 1px solid $white;
            background: $primary;
            color: $white;
        }
        .error-message {
            color: red;
            margin-top: 20px;
            display: none;
        }
        input[type="text"],
        input[type="email"] {
            border: 1px solid black;
            padding: 10px;
            width: 100%;
        }
        .btn-submit {
            display: none;
        }
        .btn-submit:disabled {
            background: #333;
            color: #777;
        }
        .slide-container {
            width: 100%;
            transition: ease-in 0.3s all;
            position: relative;
            top: 0;
            color: $white;
        }

        form#questionnaire-form {
            height: 100%;
            width: 100%;
            overflow: hidden;
        }
        .slide {
            height: 100%;
            min-height: 300px;
            width: 100%;
            padding-top: 2em;
        }
        .slide h2 {
            margin-top: 0;
        }
        button,
        label {
            cursor: pointer;
        }
    }

    .slide-info {
        color: $white;
        text-transform: none;
        line-break: normal;
        letter-spacing: normal;
        font-size: 1rem;
        border: none;
        text-align: right;
        display: block;
        padding: 1em 0;

        @include md {
            padding: 2em 0 1em;
        }
    }

    ul.slick-dots {
        position: absolute;
        bottom: -10px;
        list-style: none;
        li {
            display: none;

            button {
                font-size: 20px;
                background: transparent;
                color: black;
                width: fit-content;
                padding: 1em;

                &:before {
                    content: "";
                }
            }
        }
    }
}

.button.grey-border {
    border: none;
    background: $orange;
    color: $white;
    float: right;
    display: block;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        display: flex;
        align-items: center;
        margin-top: 1em;
    }

    & + label:before {
        content: "";
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: $white;
    }

    &:hover + label:before {
        background: lighten($orange, 20%);
    }

    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before {
        background: #d9d9d9;
    }

    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 10px;
        background: $white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $primary, 4px 0 0 $primary, 4px -2px 0 $primary,
            4px -4px 0 $primary, 4px -6px 0 $primary, 4px -8px 0 $primary;
        transform: rotate(45deg);

        @media (max-width: 500px) {
            left: 2px;
            top: 18px;
        }
    }
}

.g-recaptcha {
    margin: 0;
}

// -- Contact Us

#contact {
    #contact-form-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .column {
            width: 100%;

            &.left {
                width: calc(50% - 2em);

                @include md {
                    width: 100%;
                    margin-bottom: 2em;
                }

                h2 {
                    font-size: 2rem;
                    color: $primary;

                    @include md {
                        font-size: 1.75rem;
                    }

                    @include xs {
                        font-size: 1.5rem;
                    }
                }

                ul.contact-details {
                    padding-left: 0;
                    margin: 2em 0 0;

                    @include md {
                        margin: 1em 0 0;
                    }

                    li {
                        font-size: 1.25rem;
                        font-weight: 600;
                        margin: 1em 0;
                        list-style-type: none;

                        @include md {
                            font-size: 1.125rem;
                        }

                        @include xs {
                            font-size: 1rem;
                        }

                        img {
                            display: inline-block;
                            margin: 0 1.25em 0 0;
                            vertical-align: middle;

                            @include xs {
                                margin: 0 1em 0 0;
                                width: 3.5%;
                            }
                        }
                    }
                }

                .soc-med {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin: 0;

                    span {
                        font-size: 1.25rem;

                        @include md {
                            font-size: 1.125rem;
                        }
                    }

                    ul.social-media {
                        display: flex;
                        align-items: center;

                        li {
                            margin: 0;
                            list-style-type: none;

                            .socmed-icon img {
                                height: 24px;
                                width: 24px;
                                margin: 0 0.5em 0 0;

                                @include md {
                                    height: 18px;
                                    width: 18px;
                                }
                            }
                        }
                    }
                }

                #map {
                    min-height: 22.875rem;
                    margin-top: 2em;
                }
            }

            &.right {
                width: calc(50% - 1em);
                max-width: 600px;

                @include md {
                    width: 100%;
                    max-width: 100%;
                }

                #contact-form {
                    background: $secondary;
                    max-width: 600px;
                    margin-right: auto;
                    margin-left: auto;
                    padding: 2em;

                    .button.primary {
                        margin: 1em 0 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1320px) {
    #contact {
        #contact-form-row {
            .column {
                &.left {
                    width: calc(100% - 600px - 2em);
                }
            }
        }
    }
}

// -- Blog Index

#blog-search-form {
    display: flex;
    background: $secondary;
    max-width: 40rem;
    margin: 0 auto 2rem;
    padding: 1rem;
    gap: 1rem;

    input {
        margin: 0;
        border-color: $white;
        color: $white;

        &::placeholder {
            opacity: 0.5;
            color: $white;
        }
    }

    button {
        margin: 0;
        flex-shrink: 0;
    }
}

.blog-category-slider {
    display: inline-block;
    background-color: $primary;
    width: 100%;
    height: 100%;

    @include xs {
        padding: 0 20px;
    }
}

ul.blog-category-bar {
    margin: 0 auto;
    width: 100%;
    padding-left: 0;

    @media (max-width: 1360px) {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        max-width: 100%;
        height: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    a {
        display: inline-block;
        color: $white;
        text-decoration: none;
        font-size: 1.125rem;
        white-space: nowrap;

        &:hover {
            color: $orange;
        }
    }

    li {
        display: inline-block;
        padding: 1em 0.75em;

        &:first-child {
            padding-left: 1em;
        }

        &.specific-category-title {
            a {
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}

.news-four-col-grid {
    display: grid;
    margin: 2em 0 0;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;

    @include md {
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }

    .news-item {
        display: flex;
        background: $white;
        width: 100%;
        max-width: 400px;
        padding: 0.75em 0.75em;
        border-radius: 10px;
        border: 1px solid $primary;
        border-left-width: 15px;
        box-shadow: 0px 2px 5px rgba($black, 0.2);
        flex-direction: column;
        text-decoration: none;

        .news-item-date {
            margin-bottom: 0.5rem;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 0.8125rem;
            color: $dark-grey;
        }

        .news-item-name {
            display: block;
            font-family: $font;
            margin: 0 0 0.5em;
            font-size: 1.125rem;
        }

        .news-item-summary {
            width: 100%;
            margin-bottom: 0.5rem;
            word-break: break-all;

            > p {
                font-size: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .g-link-arrow {
            margin-top: auto;
            margin-left: auto;
            color: $primary;

            &::after {
                background-image: url('/img/arrow-right-green.svg');
            }
        }
    }
}

// -- Blog page

#blog-page-header {
    position: relative;
    width: 100%;
    height: 350px;

    @include md {
        height: 300px;
    }

    @include xs {
        height: 200px;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.35);
        z-index: 0;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

#blog-page-content {
    margin-top: 2em;
    margin-bottom: 2em;

    @include xs {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .blog-page-intro {
        display: block;
        margin-bottom: 2em;

        @include xs {
            margin-bottom: 1em;
        }

        .row {
            align-items: flex-end;

            @include md {
                align-items: flex-start;
            }

            .column.left {
                width: 70%;

                @include md {
                    width: 100%;
                }

                .blog-category {
                    display: block;
                    color: $primary;
                    font-size: 1.25rem;
                    line-height: 24px;
                    text-transform: uppercase;
                    margin: 0.5em 0;

                    @include xs {
                        font-size: 1rem;
                    }
                }

                .blog-date-author {
                    flex-direction: row;
                    justify-content: flex-start;

                    .blog-author {
                        color: $primary;
                        margin-left: 1em;
                    }
                }
            }

            .column.right {
                .social-media-links {
                    display: flex;

                    .blog-tag-p {
                        padding-right: 1em;
                    }

                    a {
                        margin-right: 0.5em;
                        text-decoration: none;

                        img {
                            height: 18px;
                        }
                    }
                }
            }
        }
    }

    .blog-page-content {
        font-size: 1.125rem;
        line-height: 24px;
        margin-bottom: 2em;

        @include xs {
            font-size: 1rem;
            margin-bottom: 2em;
        }
    }

    .blog-tags-container {
        padding: 0.5em 0;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;

        .blog-tags {
            padding-left: 0;
            display: flex;
            margin: 0.75em 0;

            li {
                list-style-type: none;

                + li {
                    margin-left: 1em;
                }

                a {
                    display: block;
                    padding: 0.5em 1em;
                    border: 1px solid $primary;
                    text-decoration: none;
                    transition: all 0.25s ease;

                    &:hover {
                        background: $primary;
                        color: $white;
                    }
                }
            }
        }
    }

    .blog-related {
        margin: 2em 0;

        .g-section-title {
            font-size: 1.5rem;
        }
    }
}

// -- Pagination
.pagination {
    margin: 3rem 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    list-style: none;
    padding-left: 0;

    @include md {
        margin: 2rem auto;
        justify-content: center;
    }

    .page-item {
        height: 100%;
        padding: 5px 10px;
        text-align: center;
        background: #c4c4c4;
        border-radius: 3px;
        margin: 0 0.25em;
        line-height: normal;

        @include xs {
            padding: 2px;
            margin: 0 0.15em;
        }

        @include xxs {
            padding: 0;
        }

        a {
            display: block;
            height: 100%;
            color: $white;
            font-weight: 600;
            text-decoration: none;
        }

        &:hover {
            color: $orange;
            text-decoration: none;
        }

        &:first-of-type {
            border-left: 0;
        }

        &.active {
            background: $primary;

            span {
                color: $white;
                font-weight: 600;
            }
        }

        &.disabled {
            background: transparent;
            color: $primary;
        }

        &.arrow {
            background: none;

            a {
                color: $primary;
            }
        }
    }
}

// -- CMS page

#cms-page-header {
    position: relative;
    width: 100%;
    height: 350px;
    margin-bottom: 3em;
    background: $secondary;
    overflow: hidden;

    @include lg {
        height: 100%;
    }

    @include md {
        margin-bottom: 2em;
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;

        @include lg {
            padding: 0;
        }
    }

    .page-header-bg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-size: auto;
        background-position: right;
        background-image: url('/img/default-page-header.png');

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            right: -10px;
            width: 1.75em;
            height: 100%;
            z-index: 3;
            background: linear-gradient(
                90deg,
                transparent,
                #30313a calc(100% - 1rem)
            );
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: $secondary;
            -webkit-clip-path: polygon(
                47% 100%,
                68% 0%,
                68% 0%,
                0% 0%,
                0% 100%
            );
            clip-path: polygon(47% 100%, 68% 0%, 68% 0%, 0% 0%, 0% 100%);
        }

        @include lg {
            height: 350px;
            background-size: cover;
            background-position: center;

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }

        @include md {
            height: 300px;
        }

        @include xs {
            height: 200px;
        }
    }

    .header-text-container {
        display: block;
        position: absolute;
        top: 50%;
        left: 40px;
        width: 588px;
        transform: translate(0, -50%);
        z-index: 2;

        @include lg {
            position: relative;
            top: unset;
            left: unset;
            width: calc(100% - 4em);
            transform: none;
            background: $secondary;
            padding: 2em 40px;
        }

        @include md {
            width: 100%;
            padding: 2em 20px;
        }

        h1 {
            font-size: 2.25rem;
            line-height: 40px;
            color: $white;
            margin: 0 0 0.25em;

            @include lg {
                font-size: 2rem;
                line-height: 38px;
            }

            @include md {
                font-size: 1.75rem;
                line-height: 36px;
            }

            @include xs {
                font-size: 1.5rem;
                line-height: 32px;
            }
        }

        h2 {
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 30px;
            color: $white;

            @include lg {
                font-size: 1.125rem;
                line-height: 20px;
                min-height: 20px;
            }

            @include md {
                font-size: 1rem;
                min-height: 40px;
            }
        }
    }

    .triangle-green {
        display: block;
        position: absolute;
        bottom: 0;
        left: 667px;
        z-index: 2;

        @include lg {
            bottom: 99px;
            z-index: 2;
            transform: rotate(158deg);
            right: -78px;
            left: unset;
        }

        @include md {
            bottom: 96px;
        }

        @include xs {
            right: -125px;
        }
    }
}

.content-block {
    margin: 3em 0;

    @include md {
        margin: 2em 0;
    }

    @include xs {
        margin: 1.5em 0;
    }

    &.cols-2 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        > div {
            max-width: calc(50% - 1rem);
            flex-basis: calc(50% - 1rem);

            @include md {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }

    &.cols-3 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        div {
            max-width: calc((100% - 4rem) / 3);
            flex-basis: calc((100% - 4rem) / 3);

            @include md {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }

    h2.content-block-title {
        font-size: 2rem;

        @include md {
            font-size: 1.75rem;
            line-height: 36px;
        }

        @include xs {
            font-size: 1.5rem;
            line-height: 32px;
        }
    }

    .text {
        > *:first-child {
            margin-top: 1rem;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    .button {
        margin-top: 0.5em;
    }

    .fr-video.fr-dvb {
        position: relative;
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        aspect-ratio: 16 / 9;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            width: 100% !important;
        }
    }

    .column-img {
        width: 100%;
    //     height: 475px;
        object-fit: cover;

    //     @include md {
    //         height: auto;
    //     }
    }
}

#section-title {
    text-align: center;
}

// -- Specific Page (Business Advisory Services Page)

#business-advisory-services #testimonials-block {
    padding-bottom: 1em;
}

#business-advisory-services #home-news {
    margin: 3em auto;
}

#book-cta {
    position: relative;
    width: 100%;
    height: 400px;
    padding: 1.5em;
    background-image: url("/img/book-cta.png");
    background-size: cover;
    background-position: center;
    border-radius: 30px 0 30px 0;

    @include lg {
        height: 350px;
    }

    @include md {
        height: 300px;
    }

    .text {
        color: $white;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        height: 100%;
        max-width: 70%;

        @include md {
            max-width: 100%;
        }
    }
}

#about-icon {
    .row {
        > div {
            max-width: calc(50% - 1rem);
            flex-basis: calc(50% - 1rem);

            @include md {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }
}

// Page Specific

// -- Home

#home {
    h3 {
        display: block;
        font-family: $font;
        margin-bottom: 0.5em;
        line-height: 40px;
        font-size: 2rem;
    }
}

#home-hero-section {
    position: relative;
    width: 100%;
    height: 600px;
    background: $secondary;
    overflow: hidden;

    @include lg {
        height: 100%;
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;

        @include lg {
            padding: 0;
        }
    }

    .hero-bg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;

        &.desktop {
            display: block;
            background-image: url("/img/mcnamara-hero-img-new.png");
            background-size: auto;
            background-position: right;
            background-repeat: no-repeat;

            @include lg {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                right: -7px;
                width: 2.5em;
                height: 100%;
                z-index: 3;
                background: linear-gradient(
                    90deg,
                    transparent,
                    #30313a calc(100% - 1rem)
                );

                @include lg {
                    background: none;
                }
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                background: $secondary;
                -webkit-clip-path: polygon(
                    47% 100%,
                    68% 0%,
                    68% 0%,
                    0% 0%,
                    0% 100%
                );
                clip-path: polygon(47% 100%, 68% 0%, 68% 0%, 0% 0%, 0% 100%);

                @include lg {
                    -webkit-clip-path: polygon(
                        44% 106%,
                        65% 0%,
                        68% 0%,
                        0% 0%,
                        0% 100%
                    );
                    clip-path: polygon(
                        44% 106%,
                        65% 0%,
                        68% 0%,
                        0% 0%,
                        0% 100%
                    );
                }
            }
        }

        &.mobile {
            display: none;
            background-image: url("/img/mcnamara-hero-img-mobile-new.png");
            background-size: cover;
            background-position: center;

            @include lg {
                display: block;
                height: 560px;
            }

            @include md {
                height: 400px;
            }

            @include xxs2 {
                height: 250px;
            }
        }
    }

    .hero-text-container {
        display: block;
        position: absolute;
        top: 50%;
        left: 40px;
        width: 588px;
        transform: translate(0, -50%);
        z-index: 2;

        @include lg {
            position: relative;
            top: unset;
            left: unset;
            width: calc(100% - 4em);
            transform: none;
            background: $secondary;
            padding: 2em 40px;
        }

        @include xs {
            padding: 2em 20px;
        }

        h1 {
            font-size: 2.125rem;
            line-height: 40px;
            color: $white;
            margin: 0 0 0.25em;

            @include lg {
                font-size: 2rem;
                line-height: 38px;
            }

            @include xs {
                font-size: 1.5rem;
                line-height: 32px;
            }
        }

        h2 {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 30px;
            color: $white;

            @include lg {
                font-size: 1.25rem;
            }

            @include xs {
                font-size: 1.125rem;
                line-height: 24px;
            }
        }
    }

    .triangle-green {
        display: block;
        position: absolute;
        bottom: -200px;
        left: 615px;
        z-index: 2;

        @include lg {
            position: absolute;
            bottom: 77px;
            left: unset;
            right: -280px;
            transform: rotate(61deg);
        }

        @include md {
            position: absolute;
            top: 15px;
            left: unset;
            bottom: unset;
            right: -250px;
            z-index: 3;
            transform: rotate(61deg);
        }

        @include xs {
            top: 15px;
            right: -265px;
        }
    }
}

#home-about {
    padding-top: 1em;
    padding-bottom: 1em;

    @include lg {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    > .text {
        max-width: 575px;
    }
}

#selling-points-gallery,
#benefits-gallery {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;

    figure {
        display: flex;
        flex-direction: column;
        min-height: 180px;
        border: 1px solid $primary;
        padding: 1.5em;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgba(39, 113, 54, 0.35);

        img {
            width: 2.75em;
        }

        figcaption {
            > *:last-child {
                margin-bottom: 0;
            }
        }

        h4 {
            font-size: 1.125rem;
            font-weight: 600;
            color: $primary;
            font-family: $font;
            margin: 0.5em 0;
        }
    }
}

#benefits-gallery {
    grid-template-columns: repeat(3, 1fr);

    figure {
        min-height: 0;

        img {
            width: 2em;
            margin-bottom: 0.25em;
        }
    }
}

#home-services {
    padding-top: 4em;
    padding-bottom: 3em;

    @include lg {
        padding-top: 0;
        padding-bottom: 1em;
    }

    .home-services-content {
        max-width: 575px;
    }
}

#our-services-gallery {
    display: grid;
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;

    @include lg {
        gap: 1em;
    }

    @include xs {
        margin: 2em 0;
        grid-template-columns: repeat(1, 1fr);
        gap: 1.5em;
    }

    .description {
        grid-column: 1 / 4;

        @include xs {
            grid-column: 1;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    figure {
        background: $secondary;
        padding: 2em;
        border-radius: 10px;
        box-shadow: 5px 5px 5px rgba($primary, 0.5);
        color: $white;

        @include xs {
            padding: 1.125em;
        }

        img {
            height: 45px;
            margin-bottom: 0.5em;

            @include xs {
                height: 34px;
            }
        }

        figcaption {
            > *:last-child {
                margin-bottom: 0;
            }
        }

        h4 {
            font-size: 1.5rem;
            font-weight: 600;
            font-family: $font;
            margin: 0.5em 0;

            @include xs {
                font-size: 1.25rem;
            }
        }
    }
}

#business-health-check-cta {
    background-size: cover;
    background-position: center;
    height: 400px;
    margin-bottom: 6em;
    padding: 2em;
    border-radius: 30px 0 30px 0;

    @include lg {
        height: 350px;
        margin-bottom: 3em;
    }

    @include md {
        height: 300px;
    }

    .business-health-check-cta-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        height: 100%;

        h3 {
            font-family: $font;
            margin: 0 0 1.25rem;
            font-weight: 600;
            font-size: 2.25rem;
            color: $white;

            @include lg {
                line-height: 38px;
                font-size: 2rem;
            }

            @include xs {
                line-height: 36px;
                font-size: 1.75rem;
            }
        }

        p {
            max-width: 510px;
            margin: 0 0 1rem;
            color: $white;

            @include md {
                margin: 0.75em 0;
            }
        }

        .button {
            margin-top: 1rem;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

#home-two-col-content {
    .row {
        margin-bottom: 6em;

        @include lg {
            margin-bottom: 3em;
        }

        .column {
            width: calc(50% - 2em);

            @include lg {
                width: 100%;
                margin-top: 1em;
            }

            .column-img {
                width: 100%;
                height: 475px;
                object-fit: cover;

                @include md {
                    height: auto;
                }
            }
        }
    }
}

#news-articles {
    margin-bottom: 6em;

    @include lg {
        margin-bottom: 3em;
    }
}

#home-faq {
    margin-bottom: 6em;
    align-items: center;

    @include lg {
        margin-bottom: 3em;
    }
}

#testimonials-block {
    margin-bottom: 6em;

    .testi-slider {
        height: 100%;
        padding: 0 2em;
        margin: 2em 0;

        .testi-slide {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2em;
            margin: 0 1em;
            height: 300px;
            border: 1px solid $primary;
            border-radius: 10px;
            transition: all 100ms ease-in-out;
            cursor: pointer;
            overflow: hidden;
            text-decoration: none;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

            &:hover {
                transition: 0.2s ease-in-out;
                box-shadow: 4px 4px 4px rgba(39, 113, 54, 0.5);
            }

            @include md {
                padding: 1em;
            }
        }

        .testi-author {
            display: block;
            margin: 0.5em 0 0;
            color: $primary;
            font-size: 1rem;
            font-weight: 600;
            line-height: 20px;
        }

        .testi-content p {
            display: -webkit-box;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            color: $black;
            font-weight: 400;
            font-size: 1rem;
            line-height: 20px;
        }

        .slick-slide:before {
            background-color: transparent;
        }

        .slick-next,
        .slick-prev {
            z-index: 2;
            float: none;
            padding: 0;
            margin: 0;
            background: none;
            color: transparent;

            &:hover {
                box-shadow: none;
            }
        }
        .slick-prev {
            left: 10px !important;
        }
        .slick-next {
            right: 10px !important;
        }
        button.slick-prev:before {
            content: "";
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            z-index: 2;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("/img/arrow-prev.svg");
            background-size: 20px 20px;
        }
        button.slick-next:before {
            content: "";
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            z-index: 2;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("/img/arrow-next.svg");
            background-size: 20px 20px;
        }
        .slick-dots {
            bottom: -32px;
            margin: 0;
            display: flex;
            width: 100%;
            justify-content: center;
            li {
                margin-left: 0.25rem;
                display: flex;
                height: auto;
                width: auto;
                border: 1px solid transparent;
            }
            button {
                background-color: rgb(82 85 99 / 50%);
                border-radius: 0;
                margin: 0rem;
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
                text-indent: -9999px;
                overflow: hidden;
                opacity: 0.9;
                width: 0.25rem;
                height: 0.25rem;
                border-radius: 100%;

                &:hover {
                    background-color: lighten($secondary, 20%);
                    box-shadow: none;
                }
            }
        }
    }

    .slick-dots li.slick-active button:before {
        color: $primary;
    }
}
