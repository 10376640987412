// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #8e8f93;

$primary: #277136;
$secondary: #2f3139;
$orange: #f26419;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 1rem 1.5rem;

// Typography
$font: "PT Sans", sans-serif;
