// Accordion

.cms-accordion-intro {
    display: block;

    h2.accordion-title {
        font-size: 2rem;
    }
}

.toggle {
    background: $secondary;
    margin: 1em 0;
    border-radius: 0 10px 0 10px;
    overflow: hidden;

    .toggle-title {
        position: relative;
        background: $secondary;
        padding: 1em 3em 1em 1em;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease;
        font-weight: 400;
        font-size: 0.95rem;
        cursor: pointer;
        color: $white;

        &::after {
            content: "\002B";
            position: absolute;
            top: 50%;
            right: 0.625em;
            width: 24px;
            height: 24px;
            transition: all 0.3s ease;
            transform: translateY(-50%);
            text-align: center;
            font-size: 1.25em;
        }
    }

    .toggle-inner {
        padding: 0.75em 1.25em 1em;
        color: $white;

        > *:last-child {
            margin-bottom: 0;
        }

        p {
            font-size: 1rem;
        }
    }

    &.active {
        .toggle-title {
            background: $primary;
            border-bottom-color: $white;
            color: $white;

            &::after {
                content: "\2212";
            }
        }
    }
}
