@import "_breakpoints.scss";

button,
.button {
    position: relative;
    display: inline-block;
    padding: 0.75em 1.125em 0.75em 1.5em;
    border: none;
    border-radius: 0 10px 0 10px;
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    margin-top: 1.25em;
    letter-spacing: 1.2px;
    line-height: inherit;
    text-decoration: none;
    color: $white;
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    background: $secondary;

    @include xs{
        margin-top: 1rem;
        font-size: 0.875rem;
        padding: 0.75em 1.25em 0.75em 1.25em;
    }

    &:after {
        content: "";
        width: 1.25em;
        height: 1.25em;
        position: relative;
        margin-left: 0.5em;
        display: inline-block;
        vertical-align: middle;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("/img/arrow-right.svg");
        transition: 0.1s ease-in;
    }

    &:hover {
        transition: 0.2s ease-in-out;
        box-shadow: 4px 4px 4px rgba(39, 113, 54, 0.5);

        &:after{
            transform: rotate(36deg);
            transition: 0.1s ease-in;
        }
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        position: relative;
        color: $white;
        background: $orange;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.75em 1.5em 0.75em 1.5em;
        border-radius: 0 10px 0 10px;

        @include xs{
            margin-top: 1rem;
            font-size: 0.875rem;
            padding: 0.75em 1.25em 0.75em 1.25em;
        }

        &:hover {
            box-shadow: 4px 4px 4px rgb(226 108 51 / 50%);
            transition: 0.2s ease-in-out;
        }
    }


    &.secondary {
        color: $white;
        background: $primary;
    }

    &.hollow {
        background: transparent;
        box-shadow: inset 0 0 0 1px $primary;
        border: none;
        color: $primary;
        margin-left: 1em;

        &:hover {
            background: $primary;
            color: $white;

            &:after{
                background-image: url("/img/arrow-right.svg");
            }
        }

        &:after {
            content: "";
            background-image: url("/img/arrow-right-green.svg");
            transition: 0.1s ease-in;
        }
    }

    &.negative{
        background: $negative;

        &:hover {
            transition: 0.2s ease-in-out;
            box-shadow: 4px 4px 4px rgb(213 83 78 / 50%);
        }
    }

    &.cta-nav {
        position: relative;
        color: $primary;
        background: $white;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.65em 1.25em 0.65em 1.25em;
        border-radius: 0 10px 0 10px;
        margin-top: 0;

        &:after {
            content: "";
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            vertical-align: middle;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/img/arrow-right-green.svg");
            transition: 0.1s ease-in;
        }
    }
}
