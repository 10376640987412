.content-block {
    &.cols-2 {
        display: flex;
        flex-wrap: wrap;

        section {
            max-width: calc(100% / 2);
            padding: 0 2em 0 0;
            flex-basis: calc(100% / 2);

            img {
                max-height: 400px;
            }
        }
    }

    &.cols-3 {
        display: flex;
        flex-wrap: wrap;

        section {
            max-width: calc(100% / 3);
            padding: 0 2em 0 0;
            flex-basis: calc(100% / 3);

            img {
                max-height: 400px;
            }
        }
    }
}
